@import "../../../styles/mixins.scss";

.customers {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;

  @include xl {
    padding: 25px 0;
  }

  @include md {
    padding: 25px;
  }

  .customers__title {
    margin-bottom: 15px;
    h4 {
      color: var(--main-color);

      @include xl {
        padding: 0 25px;
      }

      @include md {
        padding: 0;
      }
    }
  }

  .customers__charts_bar {
    width: 100%;
  }

  .customers__numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.3s;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }

    .customers__numbers_number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h4, h6 {
        color: var(--box-color);
        margin: 0;
      }

      h6::after {
        content: "";
        display: block;
        width: calc(100% + 20px);
        height: 20px;
        background-color: var(--main-color);
        margin-left: -10px;
        margin-top: -22px;
        opacity: 0.2;
        border-radius: 50px;
      }

      h6.ar::after {
        margin-left: 0;
        margin-right: -10px;
      }

      &:nth-of-type(1) {
        h6 {
          color: #0C7CE2;
          &::after {
            background-color: #0C7CE2;
          }
        }
      }

      &:nth-of-type(2) {
        h6 {
          color: #00AD1C;
          &::after {
            background-color: #00AD1C;
          }
        }
      }
    }
  }
}