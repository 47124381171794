@import "../../../styles/mixins.scss";

.earnings {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;

  @include md {
    margin-bottom: 0;
  }

  @include xs {  
    padding: 15px;
  }

  .earnings__title {
    margin-bottom: 15px;
    h4 {
      color: var(--main-color);
    }
  }

  .earnings__filter {
    margin-bottom: 15px;
    select {
      border: 1px solid var(--main-color);
      border-radius: 15px;
      padding: 5px 7px 4px;
      font-size: 14px;
      background-color: var(--main-color);
     color: #fff;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0.85;
    }
  }

  .earnings__charts {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .earnings__number {
      flex: 0 0 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -30px;

      @include lg {
        display: none;
      }

      h1 {
        color: #00AD1C;
      }
      h3 {
        color: var(--main-color);
      }
      h6 {
        font-size: 0.75rem;
        &.inc {
          color: #00AD1C;
        }
        &.dec {
          color: #FF0000;
        }
      }
    }

    & > div:last-of-type {
      width: 100%;
      flex: 1;
    }
  }
}