@import "../../styles/mixins.scss";

.forget {
  width: 450px;

  @include xs {
    width: 95%;
  }

  .forget__container {
    padding: 25px 25px 10px;
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    text-align: center;

    @include xs {
      border-radius: 15px 15px 0 0;
    }

    .forget__container__logo {
      margin-bottom: 15px;

      @include xs {
        margin-bottom: 5px;
      }

      img {
        width: 56px;
        height: 56px;
        margin: 0 auto;
      }
      h4 {
        margin: 0;
        text-align: center!important;
        color: var(--main-color);
      }
    }

    .forget__container__title {
      margin-bottom: 25px;

      @include xs {
        margin-bottom: 15px;
      }

      h3 {
        margin: 0;
        font-weight: bold;
        text-align: center!important;
        color: var(--main-color);
      }
    }

    .forget__container__form {
      margin-bottom: 25px;

      @include xs {
        margin-bottom: 15px;
      }

      .forget__container__form__input {
        margin-bottom: 10px;

        @include xs {
          margin-bottom: 0px;
        }

        label {
          margin: 0;
          font-size: 14px;
          color: #aaa;
          position: relative;
          top: -1px;
          left: 7.5%;

          @include xs {
            left: 5%;
          }
        }

        input {
          display: block;
          width: 90%;
          height: 45px;
          border-radius: 30px;
          border: 1px solid #dadada;
          padding: 0 15px;
          font-size: 14px;
          color: #696969;
          font-weight: bold;
          transition: all 0.3s ease-in-out;
          margin: 0 auto 15px;

          @include xs {
            width: 100%;
            height: 40px;
            margin-bottom: 5px;
          }

          &:focus {
            outline: none;
            border: 1px solid var(--main-color);
          }

          &::placeholder {
            color: #aaa;
            font-weight: normal;
          }
        }

        &.ar {
          label {
            left: auto;
            right: 7.5%;
            @include xs {
              right: 5%;
            }
          }
        }
      }

      .forget__container__form__button {
        display: block;
        width: 90%;
        height: 45px;
        margin: 35px auto 15px;
        border-radius: 30px;
        border: 1px solid var(--main-color);
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: var(--main-color);
        text-align: center!important;

        @include xs {
          width: 100%;
          height: 40px;
          margin: 25px auto 10px;
        }

        &:hover {
          background-color: var(--dark-main-color);
        }
      }
    }
  }

  .forget__container__footer {
    background-color: #E2E8F0;
    padding: 20px 0;
    border-radius: 0 0 30px 30px;

    @include xs {
      border-radius: 0 0 15px 15px;
    }

    p {
      margin: 0;
      font-size: 14px;
      text-align: center!important;
      color: var(--main-color);
      font-weight: bold;
    }
  }
}