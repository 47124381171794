@import "../../../styles/mixins.scss";

.tabnav {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding: 0 0 10px;
  position: relative;

  @include md {
    flex-wrap: wrap;
  }

  .tabnav_tablinks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    .tabnav_tablinks_item {
      padding: 3px 10px 3px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 5px;
      margin-bottom: 5px;
      transition: all 0.2s ease-in-out;
      color: var(--dark-main-color);

      span {
        font-size: 16px;

        @include xl {
          font-size: 15px;
        }

        @include lg {
          font-size: 14px;
        }

        @include xs {
          font-size: 13px;
        }

        i {
          margin-right: 5px;
        }

        &.ar {
          i {
            margin-right: 0;
            margin-left: 5px;
          }
        }
      }

      @include sm {
        width: calc(50% - 5px);
      }

      @include xs {
        width: calc(50% - 5px);
        font-size: 12px;
        padding: 7px 8px;
      }

      &:hover, &.active {
        font-weight: bold;
        background-color: #40749e31;
        color: var(--dark-main-color);
      }
    }
  }

  .tabnav_datepicker {
    width: 300px;
    position: absolute;
    top: -7px;
    right: 0;

    @media screen and (max-width: 1325px) {
      position: relative;
      top: unset;
      text-align: right!important;
    }

    @include md {
      flex: 0 0 100%;
      margin: 10px 0;
      text-align: center!important;
    }

    @include xs {
      width: 250px;
    }

    input {
      background-color: var(--secondary-background);
      padding: 10px;
      color: var(--text-color);
      border-radius: 5px 0 0 5px;
      border: none;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      padding: 10px 15px;
      cursor: pointer;
      outline: none;
      font-size: 14px;
      min-height: 42px;
      width: 250px;

      @include lg {
        font-size: 12px;
        width: 200px;
      }

      @include xs {
        font-size: 11px;
        width: 180px;
        min-height: 40px;
      }

      @include xxs {
        padding: 6px 12px;
        width: 180px;
        min-height: 35px;
      }
    }

    & > i {
      padding: 8px 15px;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 0 5px 5px 0;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      min-height: 42px;

      @include xs {
        font-size: 15px;
        min-height: 40px;
      }

      @include xxs {
        padding: 6px 12px;
        font-size: 14px;
        min-height: 35px;
      }
    }

    &.ar {
      right: unset;
      left: 0;

      @media screen and (max-width: 1325px) {
        text-align: left!important;
        margin-right: auto;
      }

      input {
        border-radius: 0 5px 5px 0;
        position: relative;
        top: -2px;
      }
      & > i {
        border-radius: 5px 0 0 5px;
      }
    }
  }
}