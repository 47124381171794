@import "../../../styles/mixins.scss";

.account {
  background-color: #fff;
  padding: 25px 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 25px;
  display: flex;

  @include md {
    flex-wrap: wrap;
  }

  button {
    display: block;
    width: 150px;
    height: 35px;
    margin: 25px auto 15px;
    border-radius: 10px;
    border: 1px solid var(--dark-main-color);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: var(--dark-main-color);
    text-align: center!important;

    @include sm {
      width: 110px;
    }

    &:hover {
      background-color: var(--main-color);
    }
  }

  .account__picture {
    flex: 2;

    @include md {
      flex: auto;
      border-bottom: 1px solid #eee;
      margin-bottom: 25px;
      padding-bottom: 25px;
    }
    
    .account__picture-container {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin: 0 auto;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      @include md {
        margin: 0 auto;
      }

      @include sm {
        width: 120px;
        height: 120px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .account__picture-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.15s ease-in-out;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.5rem;
          color: #fff;
          transition: all 0.2s ease-in-out;
        }
      }

      &:hover {
        .account__picture-overlay {
          transform: scale(1);
        }
      }
    }
  }

  .account__info {
    flex: 4;
    border-left: 1px solid #eee;
    padding-left: 35px;

    @include md {
      border-left: unset;
      padding: 0 35px;
      flex: 0 0 100%;
    }

    @include xs {
      padding: 0 25px;
    }

    button {
      margin: 25px 0 10px;
    }

    .account__info-input {
      margin-bottom: 10px;

      @include xs {
        margin-bottom: 0px;
      }

      label {
        margin: 0;
        font-size: 14px;
        color: #aaa;
        position: relative;
        top: -1px;
        left: 10px;
      }

      input {
        display: block;
        width: 75%;
        height: 45px;
        border-radius: 10px;
        border: 1px solid #dadada;
        padding: 0 15px;
        font-size: 14px;
        color: #696969;
        font-weight: bold;
        transition: all 0.3s ease-in-out;
        margin: 0 0 15px;

        @include xl {
          width: 85%;
        }

        @include md {
          width: 100%;
        }

        &:focus {
          outline: none;
          border: 1px solid var(--main-color);
        }

        &::placeholder {
          color: #aaa;
          font-weight: normal;
        }
      }
    }

    &.ar {
      label {
        left: auto;
        right: 10px
      }
    }
  }
}