@import "../../../styles/mixins.scss";

.nav {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 10px 10px 0 0;
  overflow: hidden;

  ul {
    display: flex;
    align-items: center;

    @include md {
      flex-wrap: wrap;
    }
    
    li {
      flex: 1;

      @include md {
        flex: auto;
      }

      @include xs {
        flex: 0 0 50%;
      }

      button {
        width: 100%;
        height: 100%;
        text-align: center!important;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: #173d5d;
        background-color: #fff;
        border: none;
        outline: none;
        padding: 10px 50px;
        border-bottom: 2px solid transparent;
        font-size: 18px;

        @include xl {
          font-size: 17px;
        }

        @include lg {
          font-size: 16px;
        }

        @include xs {
          padding: 10px 15px;
          font-size: 15px;
        }

        @include xxs {
          padding: 10px;
          font-size: 14px;
        }
        
        &:hover, &.active {
          background-color: #40749e0a;
          color: var(--dark-main-color);
          border-bottom-color: var(--main-color);
        }

        i {
          margin-right: 5px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  &.ar {
    ul {
      li {
        button {
          i {
            margin-right: 0;
            margin-left: 5px;
          }
        }
      }
    }
  }
}