@import "../../../../styles/mixins.scss";

.order_details {
  padding: 25px 35px;
  background-color: #fff;
  width: 45%;
  height: 100vh;
  border-radius: 20px 0 0 20px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  box-shadow: 0 3px 5px rgba(0,0,0,0.04);

  @include xl {
    width: 55%;
  }

  @include lg {
    width: 65%;
  }

  @include md {
    width: 75%;
    padding: 25px 30px;
  }

  @include sm {
    width: 100%;
  }

  @include xs {
    padding: 25px;
  }

  .order_details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: var(--dark-main-color);

    i {
      font-size: 24px;
      color: #0b365a;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: #ac0808;
      }
    }
  }

  .order_details_meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .order_details_status {
      span {
        font-size: 14px;
        font-weight: normal;
        color: #0d8d01;
        padding: 5px 25px;
        background-color: #0d8d012f;
        border-radius: 20px;

        i {
          margin-right: 5px;
        }

        @include xs {
          font-size: 13px;
        }

        @include xxs {
          font-size: 12px;
          padding: 5px 15px;
        }
      }
    }

    .order_details_expected {
      span {
        font-size: 14px;
        font-weight: normal;
        color: #464646;
        padding: 5px 25px;
        background-color: #4646462f;
        border-radius: 20px;

        i {
          margin-right: 5px;
        }

        @include xs {
          font-size: 13px;
        }

        @include xxs {
          font-size: 12px;
          padding: 5px 15px;
        }
      }
    }

    &.ar {
      i {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }

  .order_details_address {
    .order_details_address_details {
      margin-bottom: 10px;
      border-radius: 20px;
      color: #052947;
      font-size: 18px;
      font-weight: 100;
      padding: 0 5px;
      
      i {
        margin-right: 5px;
      }

      &.ar {
        i {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }

    .order_details_address_map {
      border-radius: 10px;
      overflow: hidden;
      margin: 15px 0;
      height: 230px;

      @include xs {
        height: 200px;
      }
    }
  }

  .order_details_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #0b365a;
    padding: 0 5px 15px;
    border-bottom: 1px solid #f6f1f1;

    @include xs {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }

    @include xxs {
      gap: 5px;
    }

    p {
      font-size: 18px;
      font-weight: 100;

      @include xs {
        font-size: 16px;
      }

      &:first-of-type {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: normal;
      }

      i {
        margin-right: 5px;
      }
    }

    img {
      width: 42px;
      height: 42px;

      @include md {
        width: 36px;
        height: 36px;
      }
    }

    &.ar {
      p:first-of-type {
        img {
          position: relative;
          top: -2px;
        }
      }
      p:last-of-type {
        direction: ltr!important;
      }
    }
  }

  .order_details_items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #f6f1f1;
    margin-bottom: 20px;

    h4 {
      flex: 0 0 100%;
      margin-bottom: 20px;
      color: var(--dark-main-color);
      cursor: pointer;

      span {
        font-size: inherit;
      }

      i {
        float: right;

        &.closed {
          transform: rotate(180deg);
        }

        &.ar {
          float: left;
        }
      }
    }

    .order_details_items_list {
      width: 100%;
      .order_details_item {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        width: 100%;
        margin-bottom: 20px;
  
        &:last-of-type {
          margin-bottom: 10px;
        }
  
        img {
          width: 72px;
          height: 72px;
          object-fit: contain;
          flex: 0 0 80px;
        }
  
        div {
          font-weight: 100;
          flex: 0 0 calc(100% - 95px);
          margin-bottom: 10px;
  
          p {
            font-weight: 100;
            font-size: 13px;
            margin: 0;

            @include xs {
              font-size: 12px;
            }
  
            &:first-of-type {
              font-size: 16px;

              @include xs {
                font-size: 14px;
              }
            }

            b {
              @include xs {
                font-size: 11px;
              }
              span {
                @include xs {
                  font-size: 11px;
                }
              }
            }
    
            span.color {
              background-color: #000;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              display: inline-block;
              position: relative;
              top: 3px;
            }
          }
        }
      }
    }
  }

  .order_details_track_timing {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f6f1f1;
    margin-bottom: 15px;

    h4 {
      flex: 0 0 100%;
      margin-bottom: 5px;
      color: var(--dark-main-color);
      cursor: pointer;

      i {
        float: right;

        &.closed {
          transform: rotate(180deg);
        }

        &.ar {
          float: left;
        }
      }
    }

    .order_details_track_timing_state {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 18px;
        top: 70%;
        width: 1px;
        height: 40px;
        background-color: #DDE6EC;
        z-index: -1;
      }

      &.ar {
        &::after {
          left: auto;
          right: 18px;
        }
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      p {
        font-size: 16px;
        color: var(--dark-main-color);

        i {
          margin-right: 5px;
          font-size: 16px;
          width: 36px;
          height: 36px;
          background-color: #DDE6EC;
          border-radius: 50%;
          color: var(--dark-main-color);
          text-align: center!important;
          line-height: 36px;

          &.ar {
            margin-right: 0;
            margin-left: 5px;
          }
        }

        @include xs {
          font-size: 14px;
        }

        &:last-of-type {
          font-size: 14px;
          font-weight: normal;
          color: #18547c98;

          @include xs {
            font-size: 12px;
          }

          i {
            font-size: 16px;
            width: unset;
            height: unset;
            background-color: unset;
            border-radius: 50%;
            color: unset;

            @include xs {
              display: none;
            }
          }

          span {
            font-size: 12px;
            font-weight: normal;
            color: #525252;
            padding: 5px 12px;
            background-color: #5252522f;
            border-radius: 10px;
    
            i {
              margin-right: 5px;
            }
    
            @include xs {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .order_details_action {
    button {
      width: 100%;
      height: 50px;
      border-radius: 20px;
      border: none;
      background-color: var(--dark-main-color);
      color: #fff;
      font-size: 18px;
      text-align: center!important;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: #0e4572;
      }
    }
  }
}