@import "../../../styles/mixins.scss";

.log {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  padding: 12px 25px 15px;
  margin: 0 0 10px;
  overflow: hidden;

  * {
    direction: ltr!important;
    text-align: left!important;
  }

  @include md {
    padding: 12px 25px 20px;
  }

  @include xs {
    padding: 12px 20px;
  }

  .log_details_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    @include md {
      flex-wrap: wrap;
    }

    i {
      position: relative;
      top: 3px;
    }

    h5 {
      font-weight: normal;
      @include md {
        flex: 0 0 100%;
      }

      @include xs {
        font-size: 13px;
      }

      i {
        margin-right: 5px;
      }

      &.ar {
        i {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }

    .log_details_header_actions {
      @include md {
        flex: 0 0 100%;
      }

      span {
        font-size: 12px;
        padding: 7px 15px;
        margin-right: 25px;
        border-radius: 5px;
        background-color: #2626261a;
        @include md {
          margin-right: 0;
          margin-left: 20px;
        }

        @include xs {
          font-size: 11px;
        }
      }

      i {
        font-size: 13px;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }

  .log_details {
    .log_details_content {
      padding: 15px 15px 0;

      @include xs {
        padding: 15px 5px 0;
      }
    }
  }
}