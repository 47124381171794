@import "../../../styles/mixins.scss";

.notifications {
  background-color: #fff;
  padding: 100px 0;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 25px;

  h3 {
    text-align: center!important;
  }
}