@import "../../../styles/mixins.scss";

.top_boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;

  @include xs {
    gap: 8px;
  }
}