@import "../../../../styles/mixins.scss";

.orderbox {
  background-color: #fff;
  padding-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  flex: 0 0 calc(25% - 25px);
  box-shadow: 0 0 7px rgba(0,0,0,0.04);

  @include xl {
    flex: 0 0 calc(33.33% - 25px);
  }

  @include lg {
    flex: 0 0 calc(50% - 12.5px);
  }

  @include md {
    flex: 0 0 calc(100%);
  }

  @include xs {
    padding-top: 0;
  }

  .orderbox_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 35px;
    border-bottom: 1px solid #f6f1f1;
    color: #0b365abd;

    &:last-of-type {
      border-bottom: none;
      background-color: #0b365a14;
    }

    @include xs {
      justify-content: center;
      flex-wrap: wrap;
      padding: 8px 20px;

      & > div {
        flex: 0 0 100%;
      }
    }

    span {
      font-size: 15px;
      font-weight: normal;
      color: #9b9b9bb4;

      i {
        margin-right: 5px;
        font-size: 14px;
      }
    }

    p {
      font-size: 13px;
      margin: 0;
    }

    .orderbox_section_status {
      p {
        font-weight: bold;
  
        &::before {
          content: "";
          margin-right: 5px;
        }

        &.received {
          color: #a5a5a5;

          &::before {
            content: "•";
            color: #a5a5a5;
          }
        }
        
        &.onDelivery {
          color: #FF9900;
  
          &::before {
            content: "•";
            color: #FF9900;
          }
        }
        &.delivered {
          color: #00AD1C;
  
          &::before {
            content: "✓";
            color: #00AD1C;
          }
        }

        &.cancelled {
          color: #cf0000;

          &::before {
            content: "✘";
            color: #cf0000;
          }
        }
      }
    }

    .orderbox_section_items {
      p {
        span {
          font-size: 12px;
          font-weight: bold;
          color: var(--main-color);
        }
      }
    }

    .orderbox_section_address {
      p {
        font-size: 14px;
      }
    }

    .orderbox_section_user {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex: 1;

      img {
        width: 34px;
        height: 34px;
        border: 1px solid #717174;
        border-radius: 50%;
        padding: 1px;
        background-color: #fff;
      }

      p {
        color: #717174;
      }
    }

    .orderbox_section_actions {
      flex: 1;
      text-align: right!important;
      
      i {
        font-size: 23px;
        margin-right: 10px;
        cursor: pointer;
        color: #9b9b9be3;
        transition: all 0.25s ease-in-out;

        @include xs {
          font-size: 18px;
        }

        &.print {
          font-size: 22px;

          @include xs {
            font-size: 17px;
          }
        }

        &.call {
          margin-right: 0;
        }

        &:hover {
          color: var(--dark-main-color);
        }
      }
    }

  }

  &.ar {
    span i {
      margin-left: 5px;
      margin-right: 0;
    }
    .orderbox_section_status {
      p {
        &::before {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }

    .orderbox_section_actions {
      text-align: left!important;
      
      i {
        margin-right: 0;
        margin-left: 10px;

        &.call {
          margin-left: 0;
        }
      }
    }
  }
}