@import "../../../styles/mixins.scss";

.report {
  flex: 0 0 calc(33.33% - 10px);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  padding: 30px 35px;
  position: relative;
  overflow: hidden;

  @include lg {
    flex: 0 0 calc(50% - 10px);
  }

  @include md {
    flex: 0 0 100%;
  }

  @include xxs {
    padding: 25px;
  }

  & > i {
    position: absolute;
    right: -5%;
    bottom: -40%;
    font-size: 230px;
    opacity: 0.03;

    @include xl {
      bottom: -31%;
    }

    @include lg {
      bottom: -30%;
    }

    @include md {
      right: -3%;
      bottom: -35%;
    }
  }

  .report_title, .report_body, .report_action {
    position: relative;
    z-index: 2;
  }

  .report_title {
    h3 {
      color: var(--dark-main-color);
      margin-bottom: 10px;
    }
  }

  .report_body {
    p {
      margin-top: 15px;
      margin-bottom: 10px;
      color: #2a5a8196;
      font-weight: 100;
    }
  }

  .report_action {
    .report_action_button {
      padding: 10px 30px;
      border-radius: 5px;
      background-color: var(--dark-main-color);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      border: none;
      outline: none;
      font-weight: 100;
      margin-top: 15px;

      &:hover {
        background-color: var(--main-color);
      }

      @include xxs {
        padding: 10px 20px;
      }

      i {
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }
  }

  &.ar {
    & > i {
      right: unset;
      left: -5%;
      bottom: -50%;
  
      @include xl {
        bottom: -50%;
      }
  
      @include lg {
        bottom: -50%;
      }
  
      @include md {
        right: unset;
        left: -3%;
        bottom: -60%;
      }
  
      @include sm {
        bottom: -50%;
      }
    }
    
    .report_action_button {
      i {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 50px 35px;

  @include xl {
    width: 55%;
  }

  @include lg {
    width: 60%;
  }

  @include md {
    width: 70%;
  }

  @include sm {
    width: 90%;
    padding: 30px 40px 35px;
  }

  @include xs {
    width: 95%;
  }

  & > i {
    position: absolute;
    top: 35px;
    right: 45px;
    font-size: 24px;
    color: var(--dark-main-color);
    cursor: pointer;
  }

  display: flex;
  flex-wrap: wrap;

  h3 {
    width: 100%;
    color: var(--dark-main-color);
    margin-bottom: 15px;
  }

  .modal_body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    input {
      width: 100%;
      flex: 0 0 100%;
      padding: 10px 15px;
      border: 1px solid #ccc;
      outline: none;
    }
  }
  
  button {
    display: block;
    padding: 10px 50px;
    margin: 25px auto 0;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: var(--dark-main-color);
    color: #fff;
    cursor: pointer;
  }
  
  &.ar {
    text-align: right;
    direction: rtl;

    h3 {
      text-align: right;
    }

    & > i {
      top: 35px;
      right: unset;
      left: 50px;
    }
  }
}