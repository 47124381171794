@import "../../../styles/mixins.scss";

.security {
  background-color: #fff;
  padding: 25px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 25px;
  display: flex;

  .form {
    flex: 0 0 65%;
  
    @include md {
      flex: 0 0 100%;
    }

    .security__input {
      label {
        margin: 0;
        font-size: 14px;
        color: #aaa;
        position: relative;
        top: -1px;
        left: 10px;
      }
  
      input {
        display: block;
        width: 80%;
        height: 45px;
        border-radius: 10px;
        border: 1px solid #dadada;
        padding: 0 15px;
        font-size: 14px;
        color: #696969;
        font-weight: bold;
        transition: all 0.3s ease-in-out;
        margin: 0 0 15px;
  
        @include xl {
          width: 90%;
        }
  
        @include lg {
          width: 100%;
        }
  
        &:focus {
          outline: none;
          border: 1px solid var(--main-color);
        }
  
        &::placeholder {
          color: #aaa;
          font-weight: normal;
        }
      }
  
      &.ar {
        label {
          left: auto;
          right: 7.5%;
          @include xs {
            right: 5%;
          }
        }
      }
    }

    &.ar {
      .security__input {
        label {
          left: auto;
          right: 15px;
        }
      }
    }
    
    button {
      display: block;
      width: 150px;
      height: 35px;
      margin: 25px 0 15px;
      border-radius: 10px;
      border: 1px solid var(--dark-main-color);
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: var(--dark-main-color);
      text-align: center!important;
  
      @include sm {
        width: 110px;
      }
  
      &:hover {
        background-color: var(--main-color);
      }
    }
  }

  .image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @include md {
      display: none;
    }

    i {
      font-size: 200px;
      color: var(--dark-main-color);
  
      @include lg {
        font-size: 170px;
      }
    }
  }
}