@import "../../styles/mixins.scss";

.sidebar {
  background-color: #fff;
  flex: 0 0 250px;
  min-height: calc(100vh - 75px);
  padding: 15px;

  @include sm {
    flex: 0 0 75px;
  }

  @include xs {
    flex: 0 0 55px;
    padding: 5px;
    min-height: calc(100vh - 60px);
  }

  .sidebar__menu {
    display: flex;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    top: 15px;

    .sidebar__menu_item {

      .sidebar__menu_link {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 8px 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: var(--dark-main-color);
        border-left: 5px solid transparent;
        border-radius: 5px 8px 8px 5px;
        opacity: 0.9;

        &.sidebar__menu_link_active, &:hover {
          opacity: 1;
          border-left-color: var(--main-color);
          background-color: rgba($color: #40739e, $alpha: 0.05);
        }

        @include xs {
          border-left: none;
        }


        &.ar {
          border-left: unset;
          border-right: 5px solid transparent;
          border-radius: 8px 5px 5px 8px;

          &.sidebar__menu_link_active, &:hover {
            border-right-color: var(--main-color);
          }

          &.sidebar__menu_link_active span {
            font-weight: bold;
          }

          @include xs {
            border-right: none;
          }

          span {
            position: relative;
            top: 3px;
            font-size: 1.1rem;
          }
  
        }

        i {
          width: 60px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.75rem;

          @include xs {
            width: 50px;
            height: 30px;
            font-size: 1.2rem;
          }
        }
          
        span {
          font-size: 1.05rem;
          font-weight: normal;

          @include sm {
            display: none;
          }
        }
      }
    }
  }
}