@import "../../../../styles/mixins.scss";

.box {
  padding: 25px 20px;
  border-radius: 7px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.03);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include lg {
    padding: 25px 15px;
    flex: 0 0 calc(33.33% - 15px);
    &:last-of-type {
      flex: 0 0 calc(100%);
    }
  }

  @include md {
    flex-direction: column;
    padding: 20px 0;
    flex: 0 0 calc(50% - 15px);
    &:last-of-type {
      flex: 0 0 calc(50% - 15px);
    }
  }

  @include xs {
    flex: 0 0 100%;
    padding: 15px 20px 5px;
    &:last-of-type {
      flex: 0 0 100%;
    }
  }

  .box__icon {
    flex: 0 0 80px;

    i {
      width: 65px;
      height: 65px;
      font-size: 32px;
      color: #fff;
      background-color: var(--main-color);
      line-height: 65px;
      text-align: center!important;
      border-radius: 50%;
    }

    @include sm {
      flex: 0 0 70px;

      i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
      }
    }

    @include xs {
      flex: 0 0 50px;

      i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
      }
    }
  }

  .box__content {
    flex: 1;

    .box__title {
      font-weight: 500;
      color: var(--dark-main-color);
      @include xs {
        margin: 0;
      }
    }

    .box__details {
      @include md {
        text-align: center!important;
      }
      @include xs {
        margin: 0;
      }
      .box__details__number {
        .box__details__number__value {
          font-size: 1.5rem;
          font-weight: bold;
          color: var(--dark-main-color);
          @include md {
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  svg {
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 200px;
    height: 100px;
    opacity: 0.7;

    &.ar {
      right: unset;
      left: 0;
      transform: rotateY(180deg);
    }
  }
}