@import "../../../styles/mixins.scss";

.orderboxes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 0 10px;
  gap: 25px;

  .pagination {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    &.ar {
      ul li:first-of-type, ul li:last-of-type {
        transform: rotateY(180deg);
      }
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 50px 35px;

  @include xl {
    width: 55%;
  }

  @include lg {
    width: 60%;
  }

  @include md {
    width: 70%;
  }

  @include sm {
    width: 90%;
    padding: 30px 40px 35px;
  }

  @include xs {
    width: 95%;
  }

  & > i {
    position: absolute;
    top: 35px;
    right: 45px;
    font-size: 24px;
    color: var(--dark-main-color);
    cursor: pointer;
  }

  display: flex;
  flex-wrap: wrap;

  h3 {
    width: 100%;
    color: var(--dark-main-color);
    margin-bottom: 15px;
  }

  p {
    width: 100%;
    color: var(--main-color);
    font-size: 18px;
    border-top: 1px solid #ccc;
    padding: 25px 0 5px;
    margin-bottom: 15px;

    i {
      margin-right: 5px;
    }

    span {
      color: var(--dark-main-color);
      font-weight: bold;
      font-size: 17px;
    }
  }

  select {
    padding: 10px 15px;
    width: calc(100% - 100px);
    border: 1px solid #ccc;
    outline: none;
  }

  button {
    padding: 10px 25px;
    border: none;
    outline: none;
    background-color: var(--dark-main-color);
    color: #fff;
    cursor: pointer;
  }
  
  &.ar {
    text-align: right;
    direction: rtl;

    h3 {
      text-align: right;
    }

    & > i {
      top: 35px;
      right: unset;
      left: 50px;
    }
  }
}