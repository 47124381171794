.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  div {
    h1 {
      font-size: 3.5rem;
      margin: 0;
      text-align: center!important;
      position: relative;
      top: 40px;
    }
    h2 {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
      text-align: center!important;
      color: var(--dark-main-color);
      position: relative;
      top: 15px;
    }
    p {
      font-size: 1.1rem;
      margin: 0;
      text-align: center!important;
      color: var(--dark-main-color);
    }
    button {
      display: block;
      margin: 15px auto 30px;
      text-align: center!important;
      background-color: var(--dark-main-color);
      padding: 7px 30px;
      border: none;
      color: #fff;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
    }

    img {
      width: 350px;
      height: 100%;
      max-width: 100%;
      vertical-align: middle;
      object-fit: cover;
    }
  }
}