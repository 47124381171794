@import "../../../../styles/mixins.scss";

.modal {
  padding: 25px 35px;
  background-color: #fff;
  width: 45%;
  height: 100vh;
  border-radius: 20px 0 0 20px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  box-shadow: 0 3px 5px rgba(0,0,0,0.04);

  @include xl {
    width: 55%;
  }

  @include lg {
    width: 65%;
  }

  @include md {
    width: 75%;
    padding: 25px 30px;
  }

  @include sm {
    width: 100%;
  }

  @include xs {
    padding: 25px;
  }

  .modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: var(--dark-main-color);

    i {
      font-size: 24px;
      color: #0b365a;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: #ac0808;
      }
    }
  }

  .modal_form {
    .modal_form_input {
      label {
        margin: 0;
        font-size: 14px;
        color: #aaa;
        position: relative;
        top: -1px;
        left: 10px;
      }
  
      input {
        display: block;
        width: 100%;
        height: 45px;
        border-radius: 10px;
        border: 1px solid #dadada;
        padding: 0 15px;
        font-size: 14px;
        color: #696969;
        font-weight: bold;
        transition: all 0.3s ease-in-out;
        margin: 0 0 15px;
  
        &:focus {
          outline: none;
          border: 1px solid var(--main-color);
        }
  
        &::placeholder {
          color: #aaa;
          font-weight: normal;
        }
      }
    }

    &.ar {
      label {
        left: auto;
        right: 15px;
      }
    }
  }

  
  
  .modal_action {
    button {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: none;
      margin-top: 15px;
      background-color: var(--dark-main-color);
      color: #fff;
      font-size: 18px;
      text-align: center!important;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &:hover {
        background-color: #0e4572;
      }
    }
  }
}