@import "../../styles/mixins.scss";

.logs {
  position: relative;

  .content {
    padding: 15px 0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
  }
  
  .logs_datepicker {
    width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right!important;
  
    @include sm {
      width: 100%;
      text-align: right!important;
      position: relative;
    }
  
    input {
      background-color: var(--secondary-background);
      padding: 10px;
      color: var(--text-color);
      border-radius: 5px 0 0 5px;
      border: none;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      padding: 10px 15px;
      cursor: pointer;
      outline: none;
      font-size: 14px;
      min-height: 42px;
      width: 250px;
  
      @include lg {
        font-size: 12px;
        width: 200px;
      }
  
      @include xs {
        font-size: 11px;
        width: 180px;
        min-height: 40px;
      }
  
      @include xxs {
        padding: 6px 12px;
        width: 180px;
        min-height: 35px;
      }
    }
  
    & > i {
      padding: 8px 15px;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 0 5px 5px 0;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      min-height: 42px;
  
      @include xs {
        font-size: 15px;
        min-height: 40px;
      }
  
      @include xxs {
        padding: 6px 12px;
        font-size: 14px;
        min-height: 35px;
      }
    }
  
    &.ar {
      right: unset;
      left: 0;
  
      @media screen and (max-width: 1325px) {
        text-align: left!important;
        margin-right: auto;
      }
  
      input {
        border-radius: 0 5px 5px 0;
        position: relative;
        top: -2px;
      }
      & > i {
        border-radius: 5px 0 0 5px;
      }
    }
  }
}