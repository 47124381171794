@import "../../../styles/mixins.scss";

.summary {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;

  @include md {
    margin-bottom: 0;
  }

  .summary__title {
    margin-bottom: 15px;
    h4 {
      color: var(--main-color);
    }
  }

  .summary__filter {
    margin-bottom: 15px;
    select {
      border: 1px solid var(--main-color);
      border-radius: 15px;
      padding: 5px 7px 4px;
      font-size: 14px;
      background-color: var(--main-color);
     color: #fff;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0.85;
    }
  }

  .summary__numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    transition: all 0.3s;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }

    .summary__numbers_number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3, h6 {
        color: var(--box-color);
        margin: 0;
      }

      h6::after {
        content: "";
        display: block;
        width: calc(100% + 20px);
        height: 11px;
        background-color: var(--main-color);
        margin-left: -10px;
        margin-top: -14px;
        opacity: 0.2;
        border-radius: 50px;
      }

      h6.ar::after {
        margin-left: 0;
        margin-right: -10px;
      }

      &:nth-of-type(1) {
        border-bottom-color: #FF9900;
        h6 {
          color: #FF9900;
          &::after {
            background-color: #FF9900;
          }
        }
      }

      &:nth-of-type(2) {
        border-bottom-color: #0C7CE2;
        h6 {
          color: #0C7CE2;
          &::after {
            background-color: #0C7CE2;
          }
        }
      }

      &:nth-of-type(3) {
        border-bottom-color: #FF4765;
        h6 {
          color: #FF4765;
          &::after {
            background-color: #FF4765;
          }
        }
      }
    }
  }

  .summary__charts {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .summary__charts_bar {
      @include lg {
        display: none;
      }
    }

    .summary__charts_progress {
      flex: 1;
      .summary__charts_progress_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        margin-bottom: 10px;

        .item_bar {
          flex: 1;
          height: 15px;
          background-color: #EBF0F4;
          border-radius: 15px;
          transition: all 0.3s;
          position: relative;
          overflow: hidden;

          span {
            display: block;
            width: 75%;
            height: 100%;
            background-color: var(--main-color);
            border-radius: 15px;
          }
        }

        .item_text {
          flex: 0 0 30%;

          @include lg {
            flex: 0 0 33.33%;
          }

          @include xs {
            flex: 0 0 36%;
          }

          @include xxs {
            flex: 0 0 40%;
          }

          p {
            font-size: 14px;
            color: var(--main-color);

            @include sm {
              font-size: 12px;
            }

            span {
              font-size: 14px;
              margin-right: 5px;
              color: var(--main-color);
              font-weight: bold;

              &.ar {
                margin-right: 0;
                margin-left: 5px;
              }

              @include sm {
                font-size: 12px;
                margin-right: 2px;

                &.ar {
                  margin-right: 0;
                  margin-left: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}