@import "../../../styles/mixins.scss";

.linking {
  background-color: #fff;
  padding: 25px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    flex: 0 0 65%;
  
    @include md {
      flex: 0 0 100%;
    }
  
    button {
      display: block;
      width: 350px;
      height: 50px;
      border-radius: 10px;
      border: none;
      background-color: #2a5a8118;
      padding: 0 35px 5px;
      font-size: 16px;
      color: var(--dark-main-color);
      transition: all 0.2s ease-in-out;
      margin-bottom: 15px;
      cursor: pointer;
      outline: none;
      text-align: center!important;
  
      @include md {
        width: 320px;
        margin: 0 auto 15px;
      }
  
      @include xs {
        padding: 0 25px 5px;
        width: 250px;
        font-size: 13px;
      }
  
      @include xxs {
        height: 40px;
        padding: 0 15px 5px;
        width: 220px;
        font-size: 13px;
      }

      i {
        margin-right: 10px;
        font-size: 20px;
        position: relative;
        top: 2px;
  
        @include xs {
          font-size: 18px;
        }
    
        @include xxs {
          font-size: 16px;
        }
      }

      &:hover {
        background-color: #2a5a81;
        color: #fff;
      }
    }

    &.ar {
      button {
        i {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
    
  }

  .image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @include md {
      display: none;
    }

    i {
      font-size: 120px;
      color: var(--dark-main-color);
  
      @include lg {
        font-size: 100px;
      }
    }
  }
}