@import "./mixins.scss";
@import "./variables.scss"; 

/***************  { General Styles For Elements }   *****************/
$mainColor: #40739e;
$h1: 2rem;
$h2: 1.714rem;
$h3: 1.5rem;
$h4: 1.286rem;
$h5: 1.07rem;
$h6: 1rem;
$p: 1rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100vh;
  font-family: var(--font-family);
  color: var(--text-color);
  font-size: 16px;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

#ar {
  --font-family: 'Baloo Bhaijaan 2';
  * {
    direction: rtl;
    text-align: right;
  }
  h1, h2, h3, h4, h5, h6, p, span, button, input, textarea, select, option, div, section {
    font-family: var(--font-family);
  }
}

#en {
  --font-family: 'Rubik';
  * {
    direction: ltr;
    text-align: left;
  }
  h1, h2, h3, h4, h5, h6, p, span, button, input, textarea, select, option, div, section {
    font-family: var(--font-family);
  }
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  color: var(--text-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

ul {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

/********************************************************************************/

/********************* Adjusting Font Sizes For All Screens *********************/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 5px 0;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

p {
  line-height: 25px;
  font-size: $p;
}

/****** Medium Screen ******/
@include md {
  span,
  p {
    font-size: 15px;
  }
}

@include xs {
  span,
  p {
    font-size: 14px;
  }
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
  object-fit: cover;
}

/*****************************************\ Selection Reset \**************************************/

::selection {
  background: $mainColor;
  color: #fff;
}

::-moz-selection {
  background: $mainColor;
  color: #fff;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: rgba($color: $mainColor, $alpha: 1);
}

.auth-layout {
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--svg-bg);
}

.main-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.page {
  background-color: #f9f9f9;
  border-radius: 15px 0 0 0;
  padding: 15px 30px;
  flex: 1;
  min-height: calc(100vh - 75px);
  overflow: hidden;

  @include sm {
    padding: 15px 20px;
  }

  @include xs {
    min-height: calc(100vh - 60px);
  }
}

#ar .page {
  border-radius: 0 15px 0 0;
}

.page footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px 30px 0;
}

.rdrCalendarWrapper {
  position: absolute;
  right: 5px;
  top: 45px;
  z-index: 9;
  box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}
 
.rdrCalendarWrapper.ar {
  right: unset;
  left: 0;
  direction: ltr!important;

  & * {
    direction: ltr!important;
  }
}

.btn-loading {
  background-color: #dadada!important;
  color: #696969!important;
  border-color: #dadada!important;
  pointer-events: none!important;
  font-size: 16px!important;

  i {
    animation-duration: 0.4s;
  }
}

.swal2-container * {
  font-family: var(--font-family);
}

svg {
  margin: auto;

  g {
    text {
      filter: none!important;
      font-family: var(--font-family)!important;
    }
  }

  line {
    stroke: transparent;
  }
  
  line.apexcharts-gridline {
    stroke: #f6f6f6;
  }
}