@import "../../styles/mixins.scss";

.navbar {
  background-color: #fff;
  flex: 0 0 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;

  @include xs {
    height: 60px;
  }

  .navbar__logo {
    flex: 0 0 250px;
    display: flex;
    align-items: center;

    @include sm {
      flex: 0 0 75px;
    }

    @include xs {
      flex: 0 0 60px;
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;

      &.ar {
        margin-left: 10px;
        margin-right: 0;
      }

      @include xs {
        width: 34px;
        height: 34px;
        position: relative;
        top: 3px;
      }
    }

    h4 {
      @include sm {
        display: none;
      }

      font-size: 1.2rem;
      font-weight: bold;
      color: var(--main-color);
      cursor: pointer;
      span {
        font-size: 0.70rem;
        font-weight: normal;
        color: var(--light-main-color);
        position: relative;
        top: -8px;
      }
    }
  }

  .navbar__menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xs {
      position: relative;
      top: 3px;
    }

    .nav__menu__breadcrumb {
      h5 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-transform: capitalize;
        width: fit-content;

        @include xxs {
          font-size: 0.85rem;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;

          &.ar {
            margin-right: 0;
            margin-left: 10px;
          }

          @include xxs {
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    .nav__menu__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 10px;
      font-size: 1.2rem;

      &.ar {
        margin-right: auto;
        margin-left: 10px;
      }
    }

    .actions__search {
      @include md {
        display: none;
      }
    }

    .actions__btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .actions__btn {
        position: relative;
        top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin-left: 25px;
        border-radius: 50%;
        color: #262626;
        font-size: 1.2rem;
        transition: all 0.2s;

        @include sm {
          margin-left: 15px;
        }

        &:hover {
          color: #6d6d6d;
        }

        i {
          cursor: pointer;
        }
      }

      &.ar {
        .actions__btn {
          margin-left: 0;
          margin-right: 25px;
        }
      }
    }
  }
}