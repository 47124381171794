@import "../../../styles/mixins.scss";

.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 35px;
  z-index: 3;

  .details_tabe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 3px 30px;

    p {
      text-transform: capitalize;
    }

    @include sm {
      p {
        font-size: 14px;
      }
    }

    @include xs {
      p {
        font-size: 13px;
      }
    }
  }

  .details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    .details_header_logo {
      img {
        width: 64px;
        height: 64px;
      }
    }

    .details_header_details {
      h4, p {
        margin: 0;
      }
    }
  }

  .details_metadata {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    background-color: #f6f6f6;
    padding: 10px 20px 10px 15px;

    p {
      font-size: 14px;
      margin: 0;
      span {
        font-size: 14px;
        font-weight: bold;
      }

      @include sm {
        font-size: 12px;
        span {
          font-size: 12px;
        }
      }
    }
  }

  .details_body_table {
    margin-top: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .details_body_table_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      border: 1px solid #e5e5e5;

      .details_body_table_header_item {
        flex: 2;
        font-weight: bold;

        @include md {
          &:nth-of-type(1) {
            flex: 1;
          }
        }

        @include sm {
          &:nth-of-type(2) {
            p {
              text-align: center!important;
            }
          }
        }

        &:nth-last-of-type(2) {
          flex: 1;
        }

        &:last-of-type {
          flex: 1;
        }
      }
    }

    .details_body_table_body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 15px;
      border: 1px solid #e5e5e5;
      border-top: none;

      @include sm {
        padding: 3px 5px;
      }

      &:nth-of-type(odd) {
        background-color: #f9f9f9;
      }

      .details_body_table_body_item {
        flex: 2;

        p {
          font-weight: 100;
          font-size: 14px;

          @include sm {
            font-size: 12px;

            span.number {
              display: none;
            }
          }
        }

        @include md {
          &:nth-of-type(1) {
            flex: 1;
          }
        }

        @include sm {
          &:nth-of-type(2) {
            p {
              text-align: center!important;
            }
          }
        }
        &:nth-of-type(1) {
          padding: 0 5px;
        }

        &:nth-last-of-type(2) {
          flex: 1;
        }

        &:last-of-type {
          flex: 1;
        }
      }

      &:last-of-type {
        padding: 10px 15px;
        p {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    
    &.order_details {
      .details_body_table_header_item, .details_body_table_body_item {
        &:nth-of-type(1) {
          flex: 0.5;
        }
        &:nth-of-type(2) {
          padding: 0 15px;
        }
        &:nth-of-type(3) {
          flex: 0.5;
        }
        &:nth-of-type(4) {
          flex: 0.5;
        }
        &:last-of-type {
          flex: 0.5;
        }
      }
    }
  }

  .details_body_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #e5e5e5;
    border-top: none;

    @include md {
      flex-wrap: wrap;
    }

    .details_body_summary_item {
      flex: 1;

      @include md {
        flex: 0 0 50%;
      }

      @include xs {
        flex: 0 0 100%;
      }

      p {
        font-size: 15px;
        &:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
}