.icon-input {
  margin: 0 10px 0;
  border-radius: 25px;
  padding: 7px 25px;
  background-color: #EBF0F4;
  position: relative;

  input {
    border: none;
    background: none;
    outline: none;
    padding-left: 20px;
    font-size: 1rem;
    color: #6d6d6d;

    &::placeholder {
      color: #A9B7C2;
    }
  }

  span {
    i {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-37.5%);
      color: #c7c7c7;
      cursor: pointer;
      animation-duration: 0.4s;
      
      &.spin {
        top: 18.5%;
        color: #262626;
      }
    }
  }
}